import { useEffect, useReducer, useRef } from 'react';
import pick from 'lodash/pick';

const initialState = {
  isLoading: false,
  displayedLoading: false,
  loadedImg: {},
  error: '',
};

const imageLoaderReducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return { ...initialState };
    case 'fetching':
      return { ...state, isLoading: true, displayedLoading: true, error: '' };
    case 'error':
      return { ...state, isLoading: false, error: action.error.message };
    case 'success':
      return {
        ...state,
        isLoading: false,
        error: '',
        loadedImg: pick(action.payload.img, ['src', 'srcSet', 'naturalWidth', 'naturalHeight']),
      };
    default:
      return state;
  }
};

const polyfillDecode = () => Promise.resolve();

export const useImageLoader = ({ src, srcSet, onLoad }) => {
  const [state, dispatch] = useReducer(imageLoaderReducer, initialState);
  const initialised = useRef(false);
  const isMounted = useRef(true);

  useEffect(() => {
    let delayedFetchingDispatch = null;

    const loadImage = async () => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const onResolve = async () => {
          /* The component may not be mounted by the time the image resolves */
          if (!isMounted.current) return;

          /* IE and JSDOM don't implement decode, so we polyfill it here*/
          if (!img.decode) img.decode = polyfillDecode;

          try {
            await img.decode();
            resolve(img);
          } catch (error) {
            reject(new Error('An Error occurred while trying to decode an image'));
          } finally {
            if (onLoad) onLoad(img);
          }
        };

        const onReject = () => reject(new Error('An Error occurred while trying to download an image'));

        img.onload = onResolve;
        img.onerror = onReject;
        img.src = src;
        img.srcSet = srcSet;
      });
    };

    const handleLoadImage = async () => {
      /*
       * To avoid instant loading 'flash' for cached images we delay setting the loading state.
       * similar to how suspense handles async loading
       */
      delayedFetchingDispatch = setTimeout(() => {
        if (!initialised.current) dispatch({ type: 'fetching' });
      }, 500);

      try {
        const img = await loadImage();
        dispatch({ type: 'success', payload: { img } });
      } catch (error) {
        dispatch({ type: 'error', error });
      } finally {
        initialised.current = true;
      }
    };

    if (src || srcSet) {
      isMounted.current = true;
      initialised.current = false;
      dispatch({ type: 'reset' });
      handleLoadImage();
    } else {
      dispatch({ type: 'error', error: new Error('no src or srcSet provided.') });
    }

    return () => {
      clearTimeout(delayedFetchingDispatch);
      isMounted.current = false;
    };
  }, [onLoad, src, srcSet]);

  return state;
};
