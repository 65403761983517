import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { Box } from '@qga/roo-ui/components';
import ImageLoader from 'components/Image/ImageLoader';

const PRELOAD_DISTANCE = '200px';

const ImageLazyLoader = ({ src, srcSet, onLoad, width, height, cover, ...rest }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: `${PRELOAD_DISTANCE} 0px ${PRELOAD_DISTANCE} 0px`,
  });

  if (inView) return <ImageLoader src={src} srcSet={srcSet} onLoad={onLoad} cover={cover} width={width} height={height} {...rest} />;

  return <Box ref={ref} width={width} height={height} />;
};

ImageLazyLoader.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  onLoad: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  cover: PropTypes.bool,
};

ImageLazyLoader.defaultProps = {
  src: null,
  onLoad: null,
  srcSet: null,
  width: null,
  height: null,
  cover: true,
};

export default ImageLazyLoader;
