import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { themeGet } from 'styled-system';
import { Box, LoadingIndicator } from '@qga/roo-ui/components';

const pulse = keyframes`
 0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
`;

const Shimmer = styled(Box)`
  background-color: ${themeGet('colors.greys.alto')};
  animation: ${pulse} 2s ease-in-out infinite;
`;

const ImageLoadingIndicator = React.forwardRef(({ width, height, cover }, ref) => {
  if ((!width && !height) || !cover) return <LoadingIndicator ref={ref} size={12} />;

  const dimensions = {
    width: width ?? '100%',
    height: height ?? '100%',
  };
  return <Shimmer {...dimensions} />;
});

ImageLoadingIndicator.displayName = 'ImageLoadingIndicator';

ImageLoadingIndicator.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  cover: PropTypes.bool,
};

ImageLoadingIndicator.defaultProps = {
  width: null,
  height: null,
  cover: true,
};

export default ImageLoadingIndicator;
